import { Service } from "../service";
import styles from "./ServiceList.styles";
import React from "react";

export const ServiceList: React.FC = () => {
	return (
		<>
			<h2 css={styles.heading}>Naše usluge</h2>
			<div css={styles.container}>
				<Service
					image="/images/terapija.jpg"
					title="Terapija pomoću konja"
					description="2 puta tjedno po 30 minuta"
					price="75€"
				/>

				<Service
					image="/images/terensko-jahanje.jpg"
					title="Terensko jahanje"
					description="45 minuta"
					price="40€"
				/>

				<Service
					image="/images/turisticko-jahanje.jpg"
					title="Turističko jahanje"
					description="1 sat i 30 minuta"
					price="100€ za jednu osobu + 40€ za svaku dodatnu osobu"
				/>

				<Service
					image="/images/skola-jahanja.jpg"
					title="Škola jahanja"
					description="20 školskih sati"
					price="450€"
				/>
			</div>
		</>
	);
};
